import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { asyncWrap } from '../../../utils/utils';
import scratchImage from '/assets/images/scrachcard.png';
// import ScrachCardModalRR from './ScrachCardModalRR';
import ScratchCardVideoUrl from '../ScratchCardVideoUrl';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { useAuth } from '../../../context/auth-context';
import Swal from 'sweetalert2';

function ScratchCardDetailRR() {
  const [userDetails, setUserDetails] = useState([]);
  const [show, setShow] = useState(false);
  const [address, setAddress] = useState();
  const [pinCode, setPinCode] = useState();
  const [ErrorMessage, setErrorMessage] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    getScratchCard();
    //eslint-disable-next-line
  }, []);

  const getScratchCard = async () => {
    const [error, result] = await asyncWrap(axios.get('/userDetails'));
    if (error?.response?.status === 401) {
      Swal.fire('Please Logout and login again');
      return;
    }
    if (!result) {
      console.log(error);
      return;
    } else {
      // const user_data = result?.data?.data;
      setUserDetails(result?.data?.data);
      // if (user_data?.VideoURL && user_data?.roleName === 'Electrician' && !user_data?.VideoURL.includes('Scratch Complete')) {
      //   setShowScratchCardVideoUrl(true)
      // }
      // setScratchCardData(result.data.data.filter((item) => !item.IsScrached));
      // setScratchCardData(result.data.data);
      setAddress(result?.data?.data?.Address3.split(', PINCODE : ')[0]);
      setPinCode(result?.data?.data?.Address3.split('PINCODE : ')[1].trim());
    }
  };

  const submitAddress = async () => {
    setDisableBtn(true);

    if (!address) {
      setErrorMessage('Please Insert Address!');
      setDisableBtn(false);
      return;
    }

    if (!pinCode) {
      setErrorMessage('Please Insert Pincode!');
      setDisableBtn(false);
      return;
    }

    if (pinCode.length !== 6) {
      setErrorMessage('Please enter a valid 6-digit pincode.');
      setDisableBtn(false);
      return;
    }
    // if (districtId === "0") {
    //   setErrorMessage("Please Select District!");
    //   setDisableBtn(false);
    //   return;
    // }

    const [error, result] = await asyncWrap(
      axios.post('/editProfile', {
        userType: userDetails.roleName,
        userId: user?.profile?.userId || user.userId,
        addressLine3: `${address}, PINCODE : ${pinCode}`,
      })
    );
    if (!result) {
      Swal.fire(error.response.data.data.message);
      window.location.reload();
    } else if (result.data?.success) {
      Swal.fire(result.data?.message).then(() => {
        window.location.reload();
      });
    } else {
      Swal.fire(result?.data?.message).then(() => {
        window.location.reload();
      });
    }
  };

  return (
    <>
      {userDetails && (
        <ScratchCardVideoUrl show={show} setShow={setShow} data={userDetails} />
      )}
      {userDetails && userDetails?.VideoURL && (
        <>
          <div className='d-flex flex-row justify-content-evenly gap-2 flex-wrap'>
            {/* {scratchcardData.length === 0 ? (
          <img
            src="/assets/images/rr-cabel/comingsoon.png"
            width={100}
            height={100}
          />
        ) : null} */}
            {/* {scratchcardData &&
          scratchcardData.map(item => ( */}
            <>
              {userDetails?.VideoURL?.includes('Scratch Complete') ? (
                <div
                  className='d-flex flex-column rounded-2 shadow-sm p-2'
                  style={{ width: '50%', height: '195px' }}
                >
                  <div className='text-center' style={{ paddingTop: '50px' }}>
                    <p className='mt-1 mb-0' style={{ fontSize: '15px' }}>
                      You Win{' '}
                    </p>
                    <p
                      className='mt-1 mb-0 fs-20 text-bold'
                      style={{ color: '#DC7832', fontSize: '18px' }}
                    >
                      {(userDetails?.VideoURL).split('Scratch')[0] || 0}
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  onClick={() => {
                    // setShow(true);
                  }}
                  className='d-flex flex-column rounded-2 shadow-sm p-2'
                  style={{ maxWidth: '200px', height: '195px' }}
                >
                  <Image
                    src={scratchImage}
                    style={{
                      width: '150px',
                      minHeight: 'auto',
                      maxHeight: '180px',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  />
                </div>
              )}
            </>
          </div>
          {userDetails?.VideoURL?.includes('Scratch Complete') &&
            (userDetails?.VideoURL).split('Scratch')[0].trim() !==
              'Better luck next time!' && (
              <div style={{ margin: '10px 10px 10px 10px' }}>
                <div
                  style={{
                    border: '1px solid #000',
                    textAlign: 'center',
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}
                >
                  Delivery Address
                </div>
                <Form className='w-100'>
                  <FormGroup className='p-0 mt-2'>
                    <Input
                      style={{ border: '1px solid #B0B0B0', width: '100%' }}
                      required
                      id='address'
                      value={address}
                      onChange={e => setAddress(e.target.value)}
                      placeholder='Enter Your Delivery Address'
                      type='textarea'
                      rows={5}
                    />
                    {/* <Label htmlFor='address'>Address</Label> */}
                  </FormGroup>
                  <FormGroup floating className='p-0'>
                    <Input
                      style={{ border: '1px solid #B0B0B0', width: '100%' }}
                      required
                      id='pinCode'
                      value={pinCode}
                      onChange={e => setPinCode(e.target.value)}
                      placeholder='Enter Your Pin Code'
                      type='text'
                    />
                    <Label htmlFor='pincode'>PinCode</Label>
                  </FormGroup>
                  <div
                    style={{
                      color: 'red',
                      fontSize: '20px',
                      textAlign: 'center',
                    }}
                  >
                    {ErrorMessage}
                  </div>
                </Form>
                <button
                  onClick={e => {
                    e.preventDefault();
                    submitAddress();
                  }}
                  disabled={disableBtn}
                  style={{ background: '#3A69DD', color: 'white' }}
                  className='fw-bold fs-5 shadow align-self-center rounded-5 px-4 py-2 border-0 mt-2'
                >
                  Submit
                </button>
              </div>
            )}
        </>
      )}
    </>
  );
}

export default ScratchCardDetailRR;
