import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import "react-circular-progressbar/dist/styles.css";
import { asyncWrap } from "../utils/utils";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { WebAppConfig } from "../context/WebAppConfig";
import { webAppConfigIsVisible } from "../utils/isVisible";
import Banner from "../component/Home/Banner";
import { Image } from "react-bootstrap";
import Swal from "sweetalert2";
import { AiFillCheckCircle } from "react-icons/ai";
import ScrachCardModal from "../component/ScrachCardModal/ScrachCardModal";
import { useAuth } from "../context/auth-context";
import ReactPlayer from "react-player";
import CustomCarousel from "../component/Home/CustomCarousel";
import VideoSlider from "../component/Home/VideoSlider";
import { setToken } from "../axiosDefaults";
import AddressPopup from "../component/Home/AddressPopup";
import RR_kabel_Video from "/assets/RR_kabel2.mp4";
import ScratchCardVideoUrl from "../component/Home/ScratchCardVideoUrl";
import LuckyWinnerBanner from "../component/Home/LuckyWinnerBanner";

export default function Home() {
  const { t } = useTranslation();
  const history = useHistory();
  const webAppConfigData = useContext(WebAppConfig);
  const [cmsValues, setCmsValues] = useState();
  const [showBanner, setShowBanner] = useState(false);
  const [kycVerify, setKycVerify] = useState(false);
  const [userApproved, setUserApproved] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const [showScratchCardVideoUrl, setShowScratchCardVideoUrl] = useState(false);
  const [showLuckyDrawBanner, setShowLuckyDrawBanner] = useState(false);

  const { user } = useAuth();
  useEffect(() => {
    getCmsValues();
    // getScratchCard();
    getUserProfile();
    // GetPointInfo();
    // eslint-disable-next-line
  }, []);

  const setNewPopupGoodNews = () => {
  //   const lastPopupShownTime = localStorage.getItem('lastPopupShownTime');
  //   const currentTime = new Date().getTime();

  //   // 24 hours in milliseconds
  //   const timeDifference = 24 * 60 * 60 * 1000;
  //   // const timeDifference = 3 

  //   if (!lastPopupShownTime || currentTime - lastPopupShownTime > timeDifference) {
  //     Swal.fire({
  //       title: "Good News",
  //       text: 'We are glad to announce a chance to earn more with our switches (maven) & switchgear range (SS1)',
  //       showDenyButton: true,
  //       showCancelButton: false,
  //       confirmButtonText: 'OK',
  //       denyButtonText: 'Cancel',
  //     });

  //     // Save the current time in localStorage
  //     localStorage.setItem('lastPopupShownTime', currentTime.toString());
  //   } else {
      Swal.fire({
        html: `
    <video width="320" height="auto" controls autoplay>
      <source src=${RR_kabel_Video} type="video/mp4">
      Your browser does not support the video tag.
    </video>
  `,
        showDenyButton: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true
      });
  //   }
  };


  const setNewPopupMessage = () => {
    Swal.fire({
      html: `<div style="text-align: left; white-space: pre-line; word-wrap: break-word;">
             സുഹൃത്തേ

             Greettings from RR KABEL.

             RR CONNECT APP വഴി കോയിൽ സ്കാൻ ചെയ്യുമ്പോൾ ലഭിക്കുന്ന Price and Points ഇപ്പോൾ കൂട്ടിയ വിവരം താങ്ങളെ സന്തോഷ പൂർവം അറിയിയിക്കുന്നു.

             പുതിയ price and points വിവരം താഴെ കൊടുക്കുന്നു

             Superex-Rs.20/-(80point's )
             Unilay-Rs.22/-(88points )
             Firex-Rs.30/-(120 points )
           </div>`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'OK',
      denyButtonText: 'Cancel',
    })
  }

  const setDistrictData = async (user_Details = userDetails) => {
    const [districtError, districtResult] = await asyncWrap(
      axios.get('/districtList', {
        params: { stateId: user_Details?.StateId },
      })
    );
    if (!districtResult) {
      console.log(districtError);
      return;
    }

    const districtOptions = districtResult.data?.data.reduce((options, district) => {
      options[district.districtId] = district.districtName;
      return options;
    }, {});

    const { value: districtId } = await Swal.fire({
      title: 'Select District',
      input: 'select',
      inputOptions: districtOptions,
      inputPlaceholder: 'Select District',
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      inputValidator: value => {
        if (!value) {
          return 'Select District!';
        }
      },
    });

    if (districtId) {
      const [error, result] = await asyncWrap(
        axios.post('/editProfile', {
            userType: user_Details.roleName,
            userId: user?.profile?.userId || user.userId,
            district: districtId
        })
      );
      if (!result) {
        Swal.fire(error.response.data.data.message);
        history.replace('/home');
      } else {
        Swal.fire(result?.data?.message).then(() => {
          // if (!user_Details?.pinCode) {
          //   setShowAddressPopup(true);
          // } else {
          //   setNewPopupGoodNews()
          // }
          window.location.reload();
        });
      }
    }
  }
  const setDistrictStateData = async (user_Details = userDetails) => {

    const [stateError, stateResult] = await asyncWrap(axios.get('/stateList'));
    
    if (!stateResult) {
      console.log(stateError);
      return;
    }

    const stateOptions = stateResult.data?.data.reduce((options, state) => {
      options[state.stateId] = state.stateName;
      return options;
    }, {});

    const { value: stateId } = await Swal.fire({
      title: 'Select State',
      input: 'select',
      inputOptions: stateOptions,
      inputPlaceholder: 'Select State',
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      inputValidator: value => {
        if (!value) {
          return 'Select State!';
        }
      },
    });

    if (stateId) {

      const [districtError, districtResult] = await asyncWrap(
        axios.get('/districtList', {
          params: { stateId: stateId },
        })
      );
      if (!districtResult) {
        console.log(districtError);
        return;
      }
  
      const districtOptions = districtResult.data?.data.reduce((options, district) => {
        options[district.districtId] = district.districtName;
        return options;
      }, {});
  
      const { value: districtId } = await Swal.fire({
        title: 'Select District',
        input: 'select',
        inputOptions: districtOptions,
        inputPlaceholder: 'Select District',
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        inputValidator: value => {
          if (!value) {
            return 'Select District!';
          }
        },
      });
  
      if (districtId) {
        const [error, result] = await asyncWrap(
          axios.post('/editProfile', {
            userType: user_Details.roleName,
            userId: user?.profile?.userId || user.userId,
            state: stateOptions[stateId],
            district: districtId
          })
        );
        if (!result) {
          Swal.fire(error.response.data.data.message);
          history.replace('/home');
        } else {
          Swal.fire(result?.data?.message).then(() => {
            // if (!user_Details?.pinCode) {
            //   setShowAddressPopup(true);
            // } else {
            //   setNewPopupGoodNews()
            // }
            window.location.reload();
          });
        }
      }
    }
    
  }

  const GetPointInfo = async (user_Details = userDetails) => {
    const [error, result] = await asyncWrap(axios.get("/earnedPoints"));
    if (!result) {
      console.log(error);
      return;
    }
    const point =
      result.data.data?.totalPoints[0]?.point -
      result.data.data?.currentYearPoints[0].EarnPoints;
    const gift_point = result.data.data?.totalPoints[1]?.point;

    const [ConfigError, ConfigResult] = await asyncWrap(axios.get("config"));
    if (ConfigError) {
      console.log(error);
    }
    const rationofpoint = ConfigResult.data.data.filter(
      item => item.FlagName === "RatioOfPoints"
    )[0].Value;
  
    if (!user_Details?.StateId && !user_Details?.districtId) {
      setDistrictStateData(user_Details)
    }
    else if (user_Details.StateId && !user_Details?.districtId) {
      setDistrictData(user_Details)
    } else if (user_Details.StateId === "0") {
      setDistrictStateData(user_Details)
    } else if (user_Details.districtId === "0") {
      setDistrictData(user_Details)
    }
    else if (!user_Details?.pinCode || user_Details.pinCode.length !== 6) {
      setShowAddressPopup(true);
    } else if (user_Details?.roleName === 'Electrician' && user_Details?.StateId === '50') {
      setNewPopupMessage()
    } else if (user_Details?.roleName === 'Electrician') {
      setNewPopupGoodNews()
    }
    // else if (user_Details?.VideoURL && user_Details?.roleName === 'Electrician' && !user_Details?.VideoURL.includes('Scratch Complete')) {
      // setShowScratchCardVideoUrl(true)
    // }
    // else {
    //   setNewPopupGoodNews()
    // }
    // if (user_Details?.roleName === 'Retailer' && user?.StateName !== 'KERALA') {
    //   Swal.fire({
    //     text: 'Redeem your Last year Points before 30th May 2024.',
    //     showDenyButton: true,
    //     showCancelButton: false,
    //     confirmButtonText: 'OK',
    //     denyButtonText: 'Cancel',
    //   }).then(result => {
    //     /* Read more about isConfirmed, isDenied below */
    //     if (result.isConfirmed) {
    //       history.replace('/schemes_points_redeem');
    //     } else {
    //       if (!user_Details?.pinCode) {
    //         setShowAddressPopup(true);
    //       }
    //     }
    //   });
    // if (!user_Details?.pinCode) {
    //   setShowAddressPopup(true);
    // }
    // if (
    //   result.data.data?.totalPoints[0]?.point > 15000 * rationofpoint &&
    //   !user_Details.IsPanVerified
    // ) {
    //   const { value: PancardValue } = await Swal.fire({
    //     title: "Enter your Pancard",
    //     input: "text",
    //     inputLabel: "Your Pan Card",
    //     showCancelButton: false,
    //     inputValue: user_Details?.PancardNumber,
    //     allowOutsideClick: false,
    //     allowEscapeKey: false,
    //     inputValidator: value => {
    //       if (!value) {
    //         return "You need to write something!";
    //       }
    //       let pancardpattern = new RegExp(/[A-Z]{5}[0-9]{4}[A-Z]{1}/);
    //       if (!pancardpattern.test(value)) {
    //         return "Please enter valid pan number";
    //       }
    //     },
    //   });

    //   if (PancardValue) {
    //     let pancardpattern = new RegExp(/[A-Z]{5}[0-9]{4}[A-Z]{1}/);
    //     if (!pancardpattern.test(PancardValue)) {
    //       Swal.fire("Please enter valid pan number");
    //       return;
    //     }

    //     const [panError, panResult] = await asyncWrap(
    //       axios.post("/verifyKyc/verify-pan", {
    //         panNumber: PancardValue,
    //       })
    //     );

    //     if (!panResult) {
    //       Swal.fire(panError.response.data.data.message).then(() => {
    //         window.location.reload();
    //       });
    //       return;
    //     }
    //     if (!panResult.data.data.valid) {
    //       Swal.fire(panError.response.data.data.message).then(() => {
    //         window.location.reload();
    //       });
    //       return;
    //     } else {
    //       const [error, result] = await asyncWrap(
    //         axios.post(
    //           "/editProfile",
    //           userDetails?.PancardNumber !== PancardValue
    //             ? {
    //                 userType: user?.profile?.roleName || user.roleName,
    //                 kycTypeId: "Pan Card",
    //                 userId: user?.profile?.userId || user.userId,
    //                 panNumber: PancardValue,
    //                 kycNumber: PancardValue,
    //                 ispanverify: 1,
    //               }
    //             : {
    //                 userType: user?.profile?.roleName || user.roleName,
    //                 userId: user?.profile?.userId || user.userId,
    //                 ispanverify: 1,
    //               }
    //         )
    //       );
    //       if (!result) {
    //         Swal.fire(error.response.data.data.message);
    //         history.replace("/home");
    //       } else if (result.data?.success) {
    //         Swal.fire(result.data?.message);
    //         history.replace("/home");
    //       } else {
    //         Swal.fire(result?.data?.message).then(() => {
    //           window.location.reload();
    //         });
    //       }
    //     }
    //   }
    // }
    
  };

  const getUserProfile = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (error?.response?.status === 401) {
      Swal.fire("Please Logout and login again");
      return;
    }
    if (!result) {
      console.log(error);
      return;
    } else {
      const user_data = result?.data?.data;
      setUserDetails(result?.data?.data);
      setUserApproved(result.data.data?.callverification);
      if (
        result.data.data?.IsAadharVerified &&
        result.data.data?.IsPanVerified
      ) {
        setKycVerify(true);
      }

      if (!result.data.data.roleId) {
        const { value: roleName } = await Swal.fire({
          title: "Select Role",
          input: "select",
          inputOptions: {
            Retailer: "Retailer",
            Electrician: "Electrician",
          },
          inputPlaceholder: "Select Role",
          showCancelButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          inputValidator: value => {
            if (!value) {
              return "Select Role!";
            }
          },
        });

        if (roleName) {
          const [error, result] = await asyncWrap(
            axios.post("/editProfile", {
              userType: roleName,
              userId: user?.profile?.userId || user.userId,
            })
          );
          if (!result) {
            Swal.fire(error.response.data.data.message);
            history.replace("/home");
          } else if (result.data?.success) {
            Swal.fire(result.data?.message).then(() => {
              localStorage.clear();
              setToken(null);
              window.location.reload();
            });
            // history.replace("/home");
          } else {
            Swal.fire(result?.data?.message).then(() => {
              window.location.reload();
            });
          }
        }
      } else if (!result.data.data.callverification) {
        Swal.fire({
          text:
            "You cannot process your redeem request till your account is approved. Please upload KYC Documents and verify your account from RR Sales Executive to be a part of RR Connect",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "KYC",
          denyButtonText: "CALL",
        }).then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            history.replace("/kycverify");
          } else if (result.isDenied) {
            Swal.fire("Call 18001036633", "", "info").then(() => {
              GetPointInfo(user_data);
            });
          } else {
            GetPointInfo(user_data);
          }
        });
      } else {
        GetPointInfo(user_data);
      }
    }
  };

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(axios.get("/cmsValues"));
    if (!result) {
      console.log(error);
      return;
    }
    setCmsValues(result.data.data);
  };

  // const getScratchCard = async () => {
  //   const [error, result] = await asyncWrap(axios.get("/scratch-card"));
  //   if (!result) {
  //     console.log(error);
  //     return;
  //   }

  //   setScratchCardData(result.data.data.filter(item => !item.IsScrached));
  //   // setScratchCardData(data);
  //   // console.log(scratchCardData)
  // };

  return (
    <div>
      {/* {scratchCardData.length !== 0 && show ? (
        <ScrachCardModal show={show} setShow={setShow} />
      ) : ( */}
      <div>
        {userDetails && (<ScratchCardVideoUrl show={showScratchCardVideoUrl} setShow={setShowScratchCardVideoUrl} data={userDetails} />)}
        {userDetails && (<LuckyWinnerBanner show={showLuckyDrawBanner} setShow={setShowLuckyDrawBanner} setShowLuckyDraw={setShowScratchCardVideoUrl} />)}
        <Banner data={cmsValues} show={showBanner} setShow={setShowBanner} />
        {userDetails && (
          <AddressPopup
            show={showAddressPopup}
            setShow={setShowAddressPopup}
            userDetail={userDetails}
          />
        )}
        <div className="d-flex flex-column">
          <div
            style={{
              background: "#fff",
            }}
            className="mx-3 my-2 home-card-top-border"
          >
            <div
              style={{
                position: "relative",
              }}
              className="d-flex flex-column p-3 text-start home-page-card"
            >
              <div className="d-flex flex-row justify-content-between">
                <div
                  style={{ width: "100%" }}
                  className="d-flex flex-column justify-content-between"
                >
                  <div>
                    <div
                      style={{ color: "#434343", fontSize: "16px" }}
                      className="fw-normal"
                    >
                      Welcome {user?.profile?.fullName || user?.fullName},{" "}
                      {user?.profile?.roleName || user?.roleName}
                    </div>
                    <div className="d-flex gap-3">
                      <div
                        style={{ color: "#434343", fontSize: "16px" }}
                        className="fw-bold"
                      >
                        {userDetails?.mobileNumber}
                      </div>
                      <div
                        style={{
                          color: "rgb(58, 105, 221)",
                          fontWeight: 600,
                          width: "80%",
                          fontSize: "14px",
                        }}
                      >
                        {userApproved ? t("user_verified") : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{ height: "auto" }}
                  className="align-self-center circle"
                >
                  <img
                    style={{ padding: "2px" }}
                    alt="img"
                    src="/assets/images/icons/CustomerVerified.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="my-2 mx-3">
            <CustomCarousel images={cmsValues?.HomePageBanner || []} />
          </div>
          {/* <div className="horizontal-scroll mt-2">
              {/* <Image
                className="img-fluid for-light  "
                style={{
                  width: "auto",
                  marginLeft: "20px",
                  height: "125px",
                }}
                src="/assets/images/rr-cabel/banner.png"
                alt="banner"
              /> 
              {cmsValues &&
                cmsValues?.HomePageBanner?.map((data, index) => (
                  <Image
                    key={index}
                    className="img-fluid for-light"
                    style={{
                      width: "90%",
                      marginLeft: "5%",
                      marginRight: "5%",
                      height: "125px",
                    }}
                    onClick={() => {
                      const newWindow = window.open(
                        data?.WebLink || data?.PDFPath
                      );
                      if (newWindow) newWindow.opener = null;
                    }}
                    src={data.BannerImage}
                    alt={data.BannerImage}
                  />
                ))}
            </div> */}
          <div className="mt-1">
            {!userApproved ? (
              <div className="d-flex flex-row flex-wrap justify-content-between mx-3 my-3">
                <div style={{ width: "48%" }} className="home-card-top-border">
                  <div
                    onClick={() => {
                      if (
                        (user?.profile?.roleName || user?.roleName) ===
                        "Retailer"
                      ) {
                        history.push(`/edit-retailer/${user.userId}`);
                      } else {
                        history.push(`/edit-profile/${user.userId}`);
                      }
                    }}
                    className="d-flex flex-column p-2 justify-content-center home-page-card"
                  >
                    <div
                      style={{ color: "#616161", fontSize: "16px" }}
                      className="fw-bold"
                    >
                      {t("edit_profile")}
                    </div>
                  </div>
                </div>

                <div style={{ width: "48%" }} className="home-card-top-border">
                  <div
                    onClick={() => history.push("/kycverify")}
                    style={{
                      position: "relative",
                    }}
                    className="d-flex flex-column p-2 justify-content-center home-page-card"
                  >
                    <div>
                      {kycVerify ? (
                        <div
                          style={{
                            position: "absolute",
                            width: "40px",
                            height: "40px",
                            top: 0,
                            right: 0,
                          }}
                        >
                          <AiFillCheckCircle
                            style={{
                              height: "auto",
                              width: "60%",
                              marginTop: "12px",
                              color: "green",
                            }}
                          />
                        </div>
                      ) : null}
                      <div
                        style={{ color: "#616161", fontSize: "16px" }}
                        className="fw-bold"
                      >
                        KYC
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="d-flex flex-row flex-wrap justify-content-between mt-1 mx-3 my-2">
              <div style={{ width: "48%" }} className="home-card-top-border">
                <div
                  onClick={() => history.push("/redeem")}
                  className="d-flex flex-column p-2 justify-content-center home-page-card"
                >
                  <div
                    style={{ color: "#616161", fontSize: "16px" }}
                    className="fw-bold"
                  >
                    {t("redeem_gift")}
                  </div>
                </div>
              </div>

              <div style={{ width: "48%" }} className="home-card-top-border">
                <div
                  onClick={() => {
                    history.push("/analytics");
                  }}
                  className="d-flex flex-column p-2 justify-content-center home-page-card"
                >
                  <div
                    style={{ color: "#616161", fontSize: "16px" }}
                    className="fw-bold"
                  >
                    Analytics
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* {(user?.profile?.roleName || user?.roleName) === "Retailer" &&
            (user?.profile?.StateName || user?.StateName) !== "KERALA" && (
              <div className="mx-3 my-2 mt-4 home-card-top-border">
                <div
                  onClick={() => history.push("/summer_bonanza")}
                  className="d-flex flex-column px-3 py-2 text-start home-page-card"
                >
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="d-flex flex-column justify-content-between">
                      <div>
                        <div
                          style={{ color: "#434343", fontSize: "16px" }}
                          className="fw-bold"
                        >
                          Summer Bonanza
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            )} */}

          <div className="mx-3 my-2 home-card-top-border">
            <div
              onClick={() => history.push("/points-info")}
              className="d-flex flex-column px-3 py-2 text-start home-page-card"
            >
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex flex-column justify-content-between">
                  <div>
                    <div
                      style={{ color: "#434343", fontSize: "16px" }}
                      className="fw-bold"
                    >
                      {t("points_information")}
                    </div>
                  </div>
                  {/* <div
                    onClick={() => history.push("/points-info")}
                    className="fw-bold"
                    style={{ color: "#3A69DD", fontSize: "16px" }}
                  >
                    {t("view")}
                  </div> */}
                </div>
                <div className="align-self-center circle">
                  <img alt="img" src="/assets/images/rr-cabel/point_info.png" />
                </div>
              </div>
            </div>
          </div>

          {/* {(user?.profile?.roleName || user?.roleName) === 'Electrician' && userDetails && userDetails?.VideoURL && (
            <div className='mx-3 my-2 home-card-top-border'>
              <div
                onClick={() => history.push('/rr-scratchcard')}
                className='d-flex flex-column px-3 py-2 text-start home-page-card'
              >
                <div className='d-flex flex-row justify-content-between align-items-center'>
                  <div className='d-flex flex-column justify-content-between'>
                    <div>
                      <div
                        style={{ color: '#434343', fontSize: '16px' }}
                        className='fw-bold'
                      >
                        Scratch Card
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}

          <div className="mx-3 my-2 home-card-top-border">
            <div
              onClick={() => history.push("/jigyasanec")}
              className="d-flex flex-column px-3 py-2 text-start home-page-card"
            >
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex flex-column justify-content-between">
                  <div>
                    <div
                      // onClick={() => history.push("/jigyasanec")}
                      style={{ color: "#434343", fontSize: "16px" }}
                      className="fw-bold"
                    >
                      Jigyasa NEC-2023
                    </div>
                    {/* <div
                      onClick={() => history.push("/jigyasanec")}
                      className="fw-bold mt-2"
                      style={{ color: "#3A69DD", fontSize: "16px" }}
                    >
                      {t("view")}
                    </div> */}
                  </div>
                </div>
                <div className="align-self-center circle">
                  <img alt="img" src="/assets/images/rr-cabel/jigyasa1.png" />
                </div>
              </div>
            </div>
          </div>

          <div className="mx-3 my-2 home-card-top-border">
            <div
              onClick={() => history.push("/switches")}
              className="d-flex flex-column px-3 py-2 text-start home-page-card"
            >
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex flex-column justify-content-between">
                  <div>
                    <div
                      style={{ color: "#434343", fontSize: "16px" }}
                      className="fw-bold"
                    >
                      Switches
                    </div>
                  </div>
                  {/* <div
                    onClick={() => history.push("/points-info")}
                    className="fw-bold"
                    style={{ color: "#3A69DD", fontSize: "16px" }}
                  >
                    {t("view")}
                  </div> */}
                </div>
                <div className="align-self-center circle">
                  <img alt="img" src="/assets/images/rr-cabel/point_info.png" />
                </div>
              </div>
            </div>
          </div>

          <div className="mx-3 my-2 home-card-top-border">
            <div
              onClick={() => history.push("/manager_details")}
              className="d-flex flex-column px-3 py-2 text-start home-page-card"
            >
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex flex-column justify-content-between">
                  <div>
                    <div
                      style={{ color: "#434343", fontSize: "16px" }}
                      className="fw-bold"
                    >
                      Manager Details
                    </div>
                  </div>
                  {/* <div
                    onClick={() => history.push("/points-info")}
                    className="fw-bold"
                    style={{ color: "#3A69DD", fontSize: "16px" }}
                  >
                    {t("view")}
                  </div> */}
                </div>
                {/* <div className="align-self-center circle">
                  <img alt="img" src="/assets/images/rr-cabel/point_info.png" />
                </div> */}
              </div>
            </div>
          </div>
          {/* {(user?.profile?.roleName || user?.roleName) === "Retailer" && (
            <div className="mx-3 my-2 home-card-top-border">
              <div
                onClick={() => history.push("/schemes_points_redeem")}
                className="d-flex flex-column px-3 py-2 text-start home-page-card"
              >
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="d-flex flex-column justify-content-between">
                    <div>
                      <div
                        style={{ color: "#434343", fontSize: "16px" }}
                        className="fw-bold"
                      >
                        Schemes Points Redeem
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}

          {/* {(user?.profile?.roleName || user?.roleName) === "Electrician" && (
            <div className="mx-3 my-2 home-card-top-border">
              <div
                onClick={() => history.push("/dosti")}
                className="d-flex flex-column px-3 py-2 text-start home-page-card"
              >
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="d-flex flex-column justify-content-between">
                    <div>
                      <div
                        style={{ color: "#434343", fontSize: "16px" }}
                        className="fw-bold"
                      >
                        Dosti 3.0
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}

          {(user?.profile?.roleName || user?.roleName) === "Electrician" && (
            <div className="mx-3 my-2 home-card-top-border">
              <div
                onClick={() => history.push("/dosti-bonus")}
                className="d-flex flex-column px-3 py-2 text-start home-page-card"
              >
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="d-flex flex-column justify-content-between">
                    <div>
                      <div
                        style={{ color: "#434343", fontSize: "16px" }}
                        className="fw-bold"
                      >
                        Dosti Bonus Schemes
                      </div>
                    </div>
                    {/* <div
                    onClick={() => history.push("/points-info")}
                    className="fw-bold"
                    style={{ color: "#3A69DD", fontSize: "16px" }}
                  >
                    {t("view")}
                  </div> */}
                  </div>
                  {/* <div className="align-self-center circle">
                  <img alt="img" src="/assets/images/rr-cabel/point_info.png" />
                </div> */}
                </div>
              </div>
            </div>
          )}
          
          {/*  */}

          {/* {(user?.profile?.roleName || user?.roleName) === "Retailer" &&
            (user?.profile?.StateName || user?.StateName) !== "KERALA" && (
              <div className="mx-3 my-2 home-card-top-border">
                <div
                  onClick={() => history.push("/year-end-bonanza")}
                  className="d-flex flex-column px-3 py-2 text-start home-page-card"
                >
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="d-flex flex-column justify-content-between">
                      <div>
                        <div
                          style={{ color: "#434343", fontSize: "16px" }}
                          className="fw-bold"
                        >
                          Year End Bonanza
                        </div>
                      </div>
                    </div>
                    <div className="align-self-center circle">
                     
                    </div>
                  </div>
                </div>
              </div>
            )} */}
          {/* {console.log((user?.profile?.StateName || user?.StateName) !== 'Kerala')} */}
          
          {(user?.profile?.roleName || user?.roleName) === 'Retailer' &&
            ((user?.profile?.StateName || user?.StateName) !== 'KERALA' &&
              (user?.profile?.StateName || user?.StateName) !== 'Kerala') && (
              <div className="mx-3 my-2 home-card-top-border">
                <div
                  onClick={() => history.push("/festival-bonanza24")}
                  className="d-flex flex-column px-3 py-2 text-start home-page-card"
                >
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="d-flex flex-column justify-content-between">
                      <div>
                        <div
                          style={{ color: "#434343", fontSize: "16px" }}
                          className="fw-bold"
                        >
                          Festival Bonanza 2024
                        </div>
                      </div>
                    </div>
                    <div className="align-self-center circle">
                     
                    </div>
                  </div>
                </div>
              </div>
            )} 

          {webAppConfigIsVisible("Scheme List", webAppConfigData) && (
            <div className="mx-3 my-2 home-card-top-border">
              <div
                onClick={() => history.push("schemes")}
                className="d-flex flex-column px-3 py-2 text-start home-page-card"
              >
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="d-flex flex-column">
                    <div
                      style={{ color: "#434343", fontSize: "16px" }}
                      className="fw-bold"
                    >
                      {t("schemes")}
                    </div>
                    {/* <div
                      style={{
                        color: "#7B7B7B",
                        width: "80%",
                        fontSize: "16px",
                      }}
                    >
                      {/* {t("schemes_desc")} 
                    </div>
                    <div
                      onClick={() => history.push("schemes")}
                      className="fw-bold mt-2"
                      style={{ color: "#3A69DD", fontSize: "16px" }}
                    >
                      {t("view")}
                    </div> */}
                  </div>
                  <div className="align-self-center circle">
                    <img alt="img" src="/assets/images/icons/Scheme.png" />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* {(user?.profile?.roleName || user?.roleName) === "Retailer" &&
            (user?.profile?.StateName || user?.StateName) !== "KERALA" && (
              <div className="mx-3 my-2 home-card-top-border">
                <div
                  onClick={() => history.push("/monsoonbonanza")}
                  className="d-flex flex-column px-3 py-2 text-start home-page-card"
                >
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="d-flex flex-column justify-content-between">
                      <div>
                        <div
                          // onClick={() => history.push("/jigyasanec")}
                          style={{ color: "#434343", fontSize: "16px" }}
                          className="fw-bold"
                        >
                          Monsoon Bonanza
                        </div>
                        {/* <div
                      onClick={() => history.push("/jigyasanec")}
                      className="fw-bold mt-2"
                      style={{ color: "#3A69DD", fontSize: "16px" }}
                    >
                      {t("view")}
                    </div> 
                      </div>
                    </div>
                    <div className="align-self-center circle">
                      <img
                        alt="img"
                        src="/assets/images/rr-cabel/jigyasa1.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )} */}

         {/*} {(user?.profile?.roleName || user?.roleName) === 'Retailer' &&
            ((user?.profile?.StateName || user?.StateName) !== 'KERALA' &&
              (user?.profile?.StateName || user?.StateName) !== 'Kerala') && (
              <div className="mx-3 my-2 home-card-top-border">
                <div
                  onClick={() => history.push("/monsoonbonanza24")}
                  className="d-flex flex-column px-3 py-2 text-start home-page-card"
                >
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="d-flex flex-column justify-content-between">
                      <div>
                        <div
                          // onClick={() => history.push("/jigyasanec")}
                          style={{ color: "#434343", fontSize: "16px" }}
                          className="fw-bold"
                        >
                          Monsoon Bonanza 2024
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>)}*/}
          
          {(user?.profile?.roleName || user?.roleName) === 'Retailer' &&
            ((user?.profile?.StateName || user?.StateName) !== 'KERALA' &&
              (user?.profile?.StateName || user?.StateName) !== 'Kerala') && (
              <div className='mx-3 my-2 home-card-top-border'>
                <div
                  onClick={() => history.push('/udaan-five')}
                  className='d-flex flex-column px-3 py-2 text-start home-page-card'
                >
                  <div className='d-flex flex-row justify-content-between align-items-center'>
                    <div className='d-flex flex-column justify-content-between'>
                      <div>
                        <div
                          // onClick={() => history.push("/jigyasanec")}
                          style={{ color: '#434343', fontSize: '16px' }}
                          className='fw-bold'
                        >
                          Udaan 5.0
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {(user?.profile?.roleName || user?.roleName) === 'Electrician' && (
            <div className='mx-3 my-2 home-card-top-border'>
              <div
                onClick={() => history.push('/dosti-five')}
                className='d-flex flex-column px-3 py-2 text-start home-page-card'
              >
                <div className='d-flex flex-row justify-content-between align-items-center'>
                  <div className='d-flex flex-column justify-content-between'>
                    <div>
                      <div
                        style={{ color: '#434343', fontSize: '16px' }}
                        className='fw-bold'
                      >
                        Dosti 5.0
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* {(user?.profile?.roleName || user?.roleName) === "Retailer" &&
            (user?.profile?.StateName || user?.StateName) !== "KERALA" && (
              <div className="mx-3 my-2 home-card-top-border">
                <div
                  onClick={() => history.push("/festival-bananza")}
                  className="d-flex flex-column px-3 py-2 text-start home-page-card"
                >
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="d-flex flex-column justify-content-between">
                      <div>
                        <div
                          // onClick={() => history.push("/jigyasanec")}
                          style={{ color: "#434343", fontSize: "16px" }}
                          className="fw-bold"
                        >
                          Festival Bonanza
                        </div>
                        
                      </div>
                    </div>
                    <div className="align-self-center circle">
                      <img
                        alt="img"
                        src="/assets/images/rr-cabel/jigyasa1.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )} */}

          <div className="mx-3 my-2 home-card-top-border">
            <div
              onClick={() => history.push("/brochure")}
              className="d-flex flex-column px-3 py-2 text-start home-page-card"
            >
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex flex-column">
                  <div
                    style={{ color: "#434343", fontSize: "16px" }}
                    className="fw-bold"
                  >
                    {t("brochure")}
                  </div>
                  {/* <div
                    style={{
                      color: "#7B7B7B",
                      width: "80%",
                      fontSize: "16px",
                    }}
                  >
                    {/* {t("brochure_desc")} 
                  </div>
                  <div
                    onClick={() => history.push("/brochure")}
                    className="fw-bold text-start mt-2"
                    style={{ color: "#3A69DD", fontSize: "16px" }}
                  >
                    {t("view")}
                  </div> */}
                </div>
                <div className="align-self-center circle">
                  <img alt="img" src="/assets/images/icons/Brochure.png" />
                </div>
              </div>
            </div>
          </div>

          {webAppConfigIsVisible("Events", webAppConfigData) && (
            <div className="d-flex flex-column shadow p-3  text-start m-3">
              <div className="d-flex flex-row justify-content-between">
                <div className="d-flex flex-column">
                  <div
                    style={{ color: "#434343", fontSize: "18px" }}
                    className="fw-bold"
                  >
                    {t("events")}
                  </div>
                  <div
                    style={{
                      color: "#7B7B7B",
                      width: "80%",
                      fontSize: "16px",
                    }}
                  >
                    {t("event_desc")}
                  </div>
                </div>
                <div className="align-self-center">
                  <img
                    alt="img"
                    src="/assets/images/icons/Events.png"
                    style={{ width: "60px" }}
                  />
                </div>
              </div>
              <div
                onClick={() => history.push("events")}
                className="fw-bold"
                style={{
                  color: "#3A69DD",
                  fontSize: "16px",
                  height: "fit-content",
                }}
              >
                {t("view")}
              </div>
            </div>
          )}
          {/* <div className="d-flex flex-column  text-start m-3">
              <ReactPlayer
                controls={true}
                width="100%"
                allowFullScreen={true}
                height={200}
                url="https://www.youtube.com/watch?v=ll3z0i1Bo9Y&ab_channel=RRKabel"
              />
            </div> */}
          <div className="m-3">
            <VideoSlider
              data={[
                "https://www.youtube.com/watch?v=ll3z0i1Bo9Y&ab_channel=RRKabel",
                "https://www.youtube.com/watch?v=6H5Q8NzaHEY",
                "https://youtu.be/Z4Ufi6zCY2k",
                "https://youtu.be/oGuQDlhnUG0",
                "https://youtu.be/gLw8hgkxRxQ",
                "https://youtu.be/u9uRLs2bTh0",
              ]}
            />
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
}

// const StyledBorderLessCard = styled(Card)`
//   height: 200px;
//   margin-bottom: 20px;
//   margin-top: 10px;
//   box-shadow: 0px 8px 30px #0000001a;
//   border: 0;
// `;
// export default Home;
