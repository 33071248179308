import axios from 'axios';
import { useEffect, useState } from 'react';
import { Carousel, Image, Modal } from 'react-bootstrap';
import ScratchCard from 'react-scratchcard-v3';
import { asyncWrap } from '../../utils/utils';
import close from '/assets/images/popclose.png';
import scratchImage from '/assets/images/scrachcard.png';
import { useAuth } from '../../context/auth-context';
import Swal from 'sweetalert2';

const ScratchCardVideoUrl = ({ show, setShow, data }) => {
  const { user } = useAuth();
  const [isComplete, setIsComplete] = useState(false); // Track completion status
  const handleScratchComplete = async () => {
    if (isComplete) return; // Exit if already complete
    setIsComplete(true); // Set as complete to prevent further calls
    const [error, result] = await asyncWrap(
      axios.post('/editProfile', {
        userType: data?.roleName,
        userId: user?.profile?.userId || user.userId,
        videoUrl: `${data?.VideoURL} Scratch ${
          new Date().toISOString()
        } Scratch Complete`,
      })
    );

    if (!result) {
      Swal.fire(error.response.data.data.message);
      window.location.reload();
    } else if (result.data?.success) {
      window.location.reload();
      console.log('complete');
    } else {
      Swal.fire(result?.data?.message).then(() => {
        window.location.reload();
      });
    }
  };
  return (
    <>
      <Modal
        fullscreen={false}
        backdrop='static'
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={show}
        // onHide={() => setShow(false)}
      >
        <Modal.Body className='m-0 p-0 text-left'>
          <div
            style={{
              position: 'absolute',
              top: '-30px', // Set desired top margin
              left: '50%',
              transform: 'translateX(-50%)', // Center horizontally
              fontSize: '20px',
              fontWeight: '600',
              color: 'white',
              textAlign: 'center',
            }}
          >
            Lucky Draw
          </div>
          {/* <Image
            src={close}
            alt=''
            className='modal-close'
            onClick={() => {
              setShow(false);
              // history.push("/home");
            }}
          /> */}
          <ScratchCard
            width={285}
            height={346}
            image={scratchImage}
            finishPercent={5}
            onComplete={handleScratchComplete}
          >
            <div className='text-center' style={{ paddingTop: '50px' }}>
              <p className='mt-1 mb-0' style={{ fontSize: '20px' }}>
                You Win{' '}
              </p>
              <p
                className='mt-1 mb-0 fs-20 text-bold'
                style={{ color: '#DC7832', fontSize: '25px' }}
              >
                {data?.VideoURL || 0}
              </p>
            </div>
          </ScratchCard>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ScratchCardVideoUrl;
